<template>
  <div v-if="ShowNav">
    <div class="bottom-nav">
      <ul class="flex1">
        <li @click="navRoute(item.path)" v-for="item in nav" :key="item.id" :class="item.path.includes(activeRoute) ? 'bottom-nav-active' : ''">{{ item.name }}</li>
      </ul>
    </div>
    <div class="bottom-nav-null"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'bottomNav',
  data() {
    return {
      // !导航
      nav: [
        { id: 10001, path: '/Home/Index', name: '首页' },
        { id: 10002, path: '/Home/Fence/FenceIndex?Id=10342', name: '习剑' },
        { id: 10003, path: '/Home/Forum?type=10342', name: '驭剑' },
        { id: 10004, path: '/Home/keenMind', name: '剑鸣' },
        { id: 10005, path: '/Home/ShoppingMall/ShopList', name: '剑盟商城' },
      ],
      // !需要展示的模块
      ShowModule: ['/Home/Index', '/Home/Fence/FenceIndex', '/Home/Forum', '/Home/keenMind','/Home/ShoppingMall']
    }
  },
  methods: {
    // !路由切换
    navRoute(route){
      this.$router.push(route);
    },
  },
  computed: {
    ...mapState(['clientWidth']),
    activeRoute() {
      return this.$route.meta.activePath
    },
    ShowNav() {
      return this.clientWidth < 750 && this.ShowModule.includes(this.$route.meta.activePath)
    }
  }
}
</script>

<style lang="scss">
  $mobil_base: 750;

  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .bottom-nav { width: 100%; height: vw(100); background: #fff; position: fixed; left: 0; bottom: 0; border-top: 1px #E4E4E4 solid; z-index: 999999; }
  .bottom-nav ul li { line-height: vw(100) }
  .bottom-nav-active { color: #6b18a8; font-weight: 700 }
  .bottom-nav-null {height: vw(100);}
</style>
<template>
  <div class="contactUs">
    <div class="innovationContest-right-box co-fff">
      <div>
        <div class="innovationContest-right-child invite" v-if="isshowinvite" @click="Invite">
          <img src="@/assets/PC/components/icon_gift.jpg" alt="">
          <div class="tc">邀请有礼</div>
        </div>
        <div v-show="IsCall" class="innovationContest-right-child cur" @click="Call">
          <p class="Call-icon tc"> <img src="@/assets/PC/components/Us.png" alt=""></p>
          <p class="Call-us tc">联系我们</p>
        </div>
      </div>
    </div>
    <!-- <div v-show="IsCall" class="contact cur" @click="Call">
      <p class="Call-icon tc"> <img src="@/assets/PC/components/Us.png" alt=""></p>
      <p class="Call-us tc">联系我们</p>
    </div> -->
    <div v-show="!IsCall" class="Call-us-shade flex1">
      <div class="Call-us-alert">
        <div class="Call-alert-title tr">
          <i class="el-icon-remove-outline cur mr co-999" @click="close"></i>
          <i class="el-icon-circle-close cur co-999" @click="close"></i>
        </div>
        <div class="Call-alert">
          <el-input v-if="!IsSuccess" type="textarea" v-model.trim="content" maxlength="200" @focus="textFocus" @blur="textBlur"></el-input>
          <div v-else class="Call-alert-success flex7">
            <div class="tc" style="width: 100%">
              <h3>您的留言已发送至管理员</h3>
              <p> 感谢您的参与！</p>
            </div>
          </div>
          <div v-if="IsPlaceholder" class="placeholders co-999">
            请针对以下几点写下您的问题和建议： <br>
            1.社区板块迭代；<br>
            2.积分金币获取；<br>
            3.社区奖惩机制；<br>
            4.日常福利活动；<br>
            5.商城商品更新；<br>
            6.其他 <br><br>
            感谢您的参与，您的宝贵意见将帮助我们不断改进。（200字以内）<br>
          </div>
        </div>
        <div class="Call-btn flex3 tr">
          <div class="Call-ercode-box">
            <img src="../../assets/PC/components/erCode.png" alt="">
            <div>
                如果您有任何问题，欢迎致电热线电话：4000566288<br>
                或通过邮件与我们联系<br>
              <a href="mailto:aveva@avevacommunity.com">aveva@avevacommunity.com</a>
            </div>
          </div>
          
          <el-button :loading="loadings" type="info" :size="clientWidth < 750 ? 'mini' : ''" @click="sendCall">发送</el-button>
        </div>
      </div>
    </div>
    <!-- 邀请好友弹窗 -->
    <div v-show="showInvite" class="shade flex0">
      <div class="invite-friends-content tc">
        <div class="invite-codes">
          <div class="invite-code-box">
            <div v-if="moduleLoading" class="module-load">
              <van-loading type="spinner" color="#000" />
            </div>
            <img :src="InviteCodeImg" alt="" width="100%" />
          </div>
        </div>
        <div class="invite-introduce mt15" style="margin-bottom: 5px">扫描二维码，生成专属海报。</div>
        <div class="invite-introduce">分享海报邀请好友扫码学习，赢取缤纷好礼！</div>
        <i class="el-icon-circle-close close-alert co-fff cur" @click="showInvite=false"></i>
      </div>
    </div>
  </div>
</template>



<script>
import { AddMessage } from "@/api/all.js";

import {
  InviteE3DQRCode,
} from "@/api/InnovationContest.js";

export default {
  name: "contactUs",
  props: ['homeroute'],
  data() {
    return {
      // 展示邀请有礼
      isshowinvite: false,
      // !展示邀请好友二维码
      showInvite: false,
      // !邀请二维码图片
      InviteCodeImg: "",
      // !模块loading
      moduleLoading: true,
      loadings: false,                         // !按钮loading
      IsCall: true,
      content: '',        // !留言内容
      IsSuccess: false,   // !是否发送成功！
      IsPlaceholder: true,// !默认提示文字 
      placeholders: '\n4.日常福利活动；\n5.商城商品更新；\n6.其他\n\n感谢您的参与，您的宝贵意见将帮助我们不断改进。（200字以内）'
    }
  },
  mounted(){
    // console.log(this.homeroute)
    // if(this.homeroute.toLowerCase()=='/home/e3dvideo'||this.homeroute.toLowerCase()=='/home/index'){
    //   this.isshowinvite = true
    // }else{
    //    this.isshowinvite = false
    // }
  },
  watch: {
		homeroute: {
      immediate: true,
			handler(newVal, oldVal) {
				if(newVal){
          console.log(newVal)
					this.homeroute = newVal
          this.isshowinvite = true
          // if(this.homeroute.toLowerCase()=='/home/e3dvideo'||this.homeroute.toLowerCase()=='/home/index'){
          //   this.isshowinvite = true
          // }else{
          //   this.isshowinvite = false
          // }
          if(this.$route.query.isinvitate){
            this.Invite()
          }
				}
			}
		}
	},
  methods: {
    // !邀请好友
    Invite() {
      if (this.clientWidth > 750) {
        this.showInvite = true;
        if (!this.InviteCodeImg) {
          this.InviteQRCode();
        }
      } else {
        this.$router.push("/E3DPoster");
      }
    },
    // !邀请好友二维码
    InviteQRCode() {
      InviteE3DQRCode().then((res) => {
        if (res.status === 1) {
          this.InviteCodeImg = res.data;
          this.moduleLoading = false;
        } else {
          this.$$toast(res.message);
        }
      });
    },
    // !获取焦点取消提示
    textFocus() {
      this.IsPlaceholder = false;
    },
    textBlur() {
      if(!this.content && !this.IsSuccess) {
        this.IsPlaceholder = true;
      }
    },
    // !点击留言
    Call() {
      this.IsCall = false;
    },
    // !关闭留言窗
    close() {
      this.IsCall = true;
    },
    // !发送留言
    sendCall() {
      if(this.$store.state.token) {
        if(!this.content) {
          this.$toast('先输入你的留言内容吧！');
          return false;
        }
        this.loadings = true;
        AddMessage(JSON.stringify(this.content)).then(res => {
          if(res.status == 1) {
            this.$toast('留言发送成功！')
            this.IsSuccess = true;
            this.content = '';
            setTimeout(() => {
              this.IsCall = true;
              this.IsSuccess = false;
              this.loadings = false;
              this.IsPlaceholder = true;
            }, 500);
          }else {   
            this.$toast(res.message);
            this.loadings = false;
          }
        })
      } else {
        this.$toast('请登录后再来留言吧！')
      }
      
    }
  },
  computed: {
    clientWidth () {
      return this.$store.state.clientWidth;
    },
  },
}
</script>

<style lang="scss" scoped>
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .contactUs{
    .innovationContest-right-box {
      position: fixed; right: 0; top: 40%; z-index: 3333;
      .innovationContest-right-child { width: 97px; padding: 10px; background: #B311EF; margin-bottom: 1px; cursor: pointer; 
        &.invite{ 
          img{ display: block; width: 40px; border-radius:50%;margin: 0 auto 5px; }
         }
      }
      .innovationContest-right-child:first-of-type { border-top-left-radius: 10px }
      .innovationContest-right-child:last-of-type { border-bottom-left-radius: 10px }
    }
    // !邀请好友
    .invite-friends-content { width: 500px; background: url("~@/assets/PC/InnovationContest/invite-friends.png") no-repeat; background-size: 100% 100%; padding-top: 90px }
    .invite-code-box { width: 150px; height: 150px; margin: 0 auto; border: 10px #E4E4E4 solid; border-radius: 5px; position: relative }
    .invite-introduce { font-size: 16px; font-weight: bold; margin-bottom: 50px }
    .invite-introduce b { font-size: 16px }

    .contact {width: 85px;height: 55px;background: #6b18a8;border-radius: 45px 0 0 45px; padding: 5px 0 0 20px; z-index: 10001;}
    .Call-us {color: #fff;padding-top: 5px;}

    .Call-icon img {width: 20px;}
    .Call-us-shade {position: fixed; left: 0; top: 0; width: 100%; height: 100%; background: rgba(0, 0, 0, 0.4); z-index: 10001;}
    .Call-us-alert {width: 800px; background: #fff; border-radius: 5px; padding: 20px;}
    
    .Call-alert-title {padding-bottom: 15px; border-bottom: 1px #ccc solid; margin-bottom: 40px;}
    .Call-alert-title i {font-size: 23px;}
    .Call-alert-title .el-icon-circle-close {margin-left: 15px;}

    .Call-alert {background: #f5efef; position: relative;}
    .placeholders {position: absolute; top: 5px; left: 10px;}
    .Call-btn {padding-top: 15px;}
    .Call-btn .Call-ercode-box{display: flex; align-items: center; justify-content: flex-start;}
    .Call-btn img{width: 55px;}
    .Call-ercode-box div{text-align: left; margin-left: 10px; color: #6b18a8;}
    .Call-ercode-box div a{text-decoration: underline;}
    .Call-alert-success {height: 200px;}
  }

  @media (max-width: 750px) {
    .contactUs{
      // !邀请好友
      .innovationContest-right-box {
        .innovationContest-right-child { width: 70px; padding: 5px; background: #B311EF; margin-bottom: 1px; cursor: pointer;
        &.invite{ 
          img{ display: block; width: 30px; border-radius:50%;margin: 0 auto 3px; }
         }
        }
        .innovationContest-right-child .tc { font-size: vw(16) }
      }
      .invite-friends-content{width: vw(700); position: relative; padding-top: vw(180);}
      .contact {width: vw(130); height: vw(109);padding: vw(20) 0 0 vw(18)}
      .Call-icon img {width: vw(26);}
      .Call-us {font-size: vw(16); padding-top: vw(6);}
      .Call-us-alert {width: 100%; margin: 0 vw(40); padding: vw(20);}

      .Call-alert-title {padding-bottom: vw(15); margin-bottom: vw(30);}
      .Call-alert-title i {font-size: vw(28)}
      .Call-alert-title .el-icon-circle-close {margin-left: vw(10);}

      .placeholders {font-size: vw(24);}
      textarea {height: vw(480);}

      .Call-btn img{width: vw(80);}
      .Call-ercode-box div{margin-left: vw(10); font-size: vw(24);}
      .Call-ercode-box div a{font-size: vw(24);}
    }
  }

</style>
<style lang="scss">
  $mobil_base: 750;
  @function vw($true) {
    @return ($true / $mobil_base) * 100vw;
  }
  .contactUs textarea {border: 0; resize: none;height: 200px;padding-left: 10px; background: none; position: relative; z-index: 1;}
  @media (max-width: 750px) {
    .Call-alert textarea{font-size: vw(24);}
    .contactUs textarea {padding-left: vw(25);}
  }
</style>
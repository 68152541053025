<template>
  <div class="Footers flex0">
    <div class="footer-content">
      <div class="footer-top">
        <div class="footer-left">
          <div>©2021-{{ currentYear }} AVEVA Group pic 及其子公司。保留所有权利。</div>
          <div>AVEVA 和 AVEVA 徽标是 AVEVA Group pic 在美国和其他国家/地区的商标或注册商标。</div>
          <a href="https://www.aveva.com/en/legal/terms-of-use/" target="_blank">条款和条件</a>
          <a class="footer-record" target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502020081"
            style="margin-top: 3px;display:block;text-decoration:none;">
            <img src="../assets/PC/components/beian.png" style="float: left;" />
            <p style="float:left;margin-left: 5px; color:#fff;">沪公网安备 31011502020081号</p>
          </a>
        </div>
        <div class="footer-right">
          <div>
            <img src="../assets/PC/components/erCode.png" alt="">
            <div>关注剑维软件官方微信号</div>
          </div>
          <div> 如果您有任何问题，欢迎致电热线电话：4000566288<br> 或通过邮件与我们联系<br><a
              href="mailto:aveva@avevacommunity.com">aveva@avevacommunity.com</a></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footers",
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  },
  computed: {
    clientWidth() {
      return this.$store.state.clientWidth;
    }
  }
}
</script>

<style lang="scss" scoped>
$mobil_base: 750;

@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}

.Footers {
  background: #232f3e;
  color: #FFF;
}

.footer-content {
  width: 100%;
  max-width: 1200px;
  align-items: center;
  padding: 10px 10px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
}

.footer-left {
  flex: 1;

  a {
    color: #1b539c !important;
    text-decoration: underline;
  }
}

.footer-right {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 60px;
    height: 60px;
  }

  div {
    &:nth-of-type(1) {
      margin-top: 7px;
      margin-right: 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

  }

  a {
    color: #fff !important;
    text-decoration: underline;
  }
}

.footer-record {
  width: 100%;
  text-align: center;

  img {
    height: 20px;
  }

  p {
    font-size: 14px;
  }
}

// ?mobile
@media (max-width: 750px) {
  .footer-content {
    min-height: vw(210);
    padding: vw(20) vw(15);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-top {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .footer-content div,
  a {
    font-size: vw(20);
  }

  .footer-left {
    width: 100%;
  }

  .footer-right {
    width: 100%;
    justify-content: center;
    align-items: flex-end;
    margin-top:20px ;

    img {
      width: vw(100);
      height: vw(100);
    }

    div {
      margin-top: vw(10);

      &:nth-of-type(1) {
        div {
          white-space: nowrap;
        }
      }
    }
  }

  .footer-record {
    width: 100%;
    text-align: center;

    img {
      height: vw(30)
    }

    p {
      font-size: vw(20) !important;
      margin-top: 2px;
    }
  }
}
</style>
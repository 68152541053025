<template>
  <div class="home">
    <Headers></Headers>
    <div class="HomeDetail">
      <router-view  />
    </div>
    <!-- <div v-if="!path.includes(this.$route.name)">
      <contactUs :homeroute="routepath"></contactUs>
    </div> -->
    <Footers></Footers>
    <foot-nav></foot-nav>
  </div>
</template>

<script>

import contactUs from "@/components/contactUs/contactUs.vue";
import Headers from '@/components/header.vue';
import FootNav from '@/components/BottomNav/index.vue';
import Footers from '@/components/footer.vue';


export default {
  data(){
    return{
      // !隐藏 联系我们的 路由配置
      // path: ['Live','Integral','FissionPosters'],
      path: ['FissionPosters','E3DPoster'],
      routepath: this.$route.path
    }
  },
  watch: {
		$route: {
      immediate: true,
			handler(newVal, oldVal) {
				if(newVal){
					this.routepath = newVal.path
				}
			},
		}
	},
  components:{
    Headers,
    contactUs,
    FootNav,
    Footers
  }
};
</script>
<style lang="scss">
$mobil_base: 750;
@function vw($true) {
  @return ($true / $mobil_base) * 100vw;
}
.home {padding-top: 60px;height: 100%;}
.HomeDetail{
    min-height: calc(100vh - 166px)
}
@media (max-width: 750px) {
  .home {padding-top: vw(100);}
  .HomeDetail{
    min-height: calc(100vh - 35vw)
  }
}
</style>
